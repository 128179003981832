.editor-wrapper {
    border: 1px solid #EEF0F6;
    border-radius: 4px;
}

.editor-wrapper.error{
    border: 1px solid #F55858 !important;
}

.anwser-editor {
    padding: 0px 12px;
    width: 100%;
}
.anwser-questions.anwser-editor {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}
.anwser-questions.anwser-editor.view-only {
    width: 390px;
}

.anwser-questions.view-only {
    border: 0;
    box-shadow: none;
}


.editor-editor {
    padding: 16px;
    min-height: 400px;
    border: 1px solid #ECECED;
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.questions-wrapper {
    border: 1px solid #BEC0CA;
    border-radius: 4px;
}

.questions-wrapper .public-DraftStyleDefault-block {
    padding: 0 !important;
}

.questions-wrapper .public-DraftEditorPlaceholder-root {
    color: #B3B1B8;
}

.anwser-questions {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #110C22;
    background: white;
    border: 1px solid #ECECED;
    border-radius: 8px;
    box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
}

.anwser-questions .public-DraftEditorPlaceholder-root {
    padding: 10px;
    color: #B3B1B8;
    font-style: italic;
}

.anwser-questions:hover,
.anwser-questions:focus,
.anwser-questions:active {
    border: 1px solid #A797FF;
    box-shadow: 0px 0px 0px 4px #E2DCFF, 0px 2px 4px 0px rgba(17, 12, 34, 0.12);
}

.anwser-questions.view-only:focus,
.anwser-questions.view-only:active,
.anwser-questions.view-only:hover {
    border: 0;
    box-shadow: none;
}

.anwser-questions .public-DraftStyleDefault-block {
    padding: 10px !important;
}

.answer-toolbar.hidden {
    display: none;
}

.answer-toolbar {
    border: unset;
    position: absolute;
    top: -44px;
    left: 0;
    border: 1px solid #ECECED;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #F8F8F8;
}

.editor-toolbar {
    border: 0;
    border-bottom: 1px solid #ECECED;
    margin-bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #F8F8F8;
}

.editor-toolbar.error {
    border-bottom: 1px solid #FF8080 !important;
    /* box-shadow: 0px 0px 0px 4px #FFE0E0, 0px 2px 4px 0px rgba(17, 12, 34, 0.12) !important; */
    box-shadow: none !important;
}

.editor-toolbar .rdw-option-wrapper,
.answer-toolbar .rdw-option-wrapper {
    height: 25px !important;
    padding: 8px !important;
    border: unset;
    border-radius: 4px;
    box-shadow: unset !important;
    background: transparent !important;
}

.editor-toolbar .rdw-option-wrapper.rdw-option-active,
.answer-toolbar .rdw-option-wrapper.rdw-option-active {
    background-color: #a6a7ad !important
}


.questions-editor .DraftEditor-editorContainer .public-DraftEditor-content {
    min-height: 400px;
}

.answer-editor-view-only {
    gap: 16px;
}

.DraftEditor-editorContainer {
    font-style: normal;
}

.editor-wrapper .editor-image-popup .rdw-image-modal-size {
    display: none;
}