.container {
  width: 1140px;
}
.lg-container {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .lg-container {
    width: 1140px;
  }
}

.fullscreen-enabled {
  background: #f0f2f5;
  overflow: auto;
}

body {
  margin: 0;
  font-family: SVN-Poppins, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  color: #393F52;
}

/* Override antd checkbox */
.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-checkbox-wrapper .ant-checkbox {
  top: 0;
}

.purple-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #393F52 !important;
  border-color: #393F52 !important;
}

.purple-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7357FF !important;
  border-color: #7357FF !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  left: 30.5%;
}

.ant-input-prefix {
  margin-right: 16px !important;
}

.has-clear-icon .ant-input {
  margin-right: 30px;
}

.ant-picker-suffix {
  margin-right: 16px !important;
}

.ant-picker .ant-picker-input,
.ant-picker.ant-picker-range {
  display: flex;
  flex-direction: row-reverse;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 0;
  margin-right: 5px;
}

.ant-select-single:not(.select-no-icon).ant-select-show-arrow .ant-select-selection-item {
  padding-right: 0px;
}



.ant-select-single:not(.select-no-icon).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single:not(.select-no-icon).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0px;
}

.has-icon.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.has-icon.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-left: 35px !important;
}

.has-icon.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-left: 35px !important;
}

.hover-icon:hover>svg:not(.svg-stroke)>path {
  fill: #7357FF !important;
}

.hover-icon:hover>svg.svg-stroke>path {
  stroke: #7357FF !important;
}

.hover-icon:hover>svg>g>g>path {
  fill: #7357FF !important;
}

/* override antd table header color */
.ant-table-wrapper.white-header thead[class*="ant-table-thead"] th {
  background-color: white !important;
}

/* override antd table header color */
.ant-table-wrapper:not(.white-header) thead[class*="ant-table-thead"] th {
  background-color: #E2E4EB !important;
}

/* Override Modal */
.ant-modal-centered .ant-modal {
  box-shadow: 0px 4px 16px 0px rgba(98, 112, 140, 0.24);
  border-radius: 12px;
  box-sizing: border-box;
  overflow: hidden;
}
.ant-modal-close {
  display: none;
}
.ant-modal-header {
  border-bottom: 0;
}
.ant-modal-footer {
  border-top: 0;
}
/* Override Modal */

.ant-table-content>table {
  border-radius: 10px !important;
  overflow: hidden;
  border: 1px solid #E2E4EB !important;
}

.ant-table-content>table>.ant-table-tbody>tr {
  border: 1px solid #E2E4EB !important;
}

.no-border .ant-table-thead>tr>th {
  border: none !important;
}

.no-border .ant-table-content>table>.ant-table-tbody>tr,
.no-border .ant-table-content>table>.ant-table-tbody>tr>td {
  border: none !important;
}

.ant-table-cell::before {
  content: "";
  display: none;
}

.ant-table.ant-table-empty .ant-table-tbody>tr>td {
  border-bottom: 0 !important;
}

.no-border.white-header .ant-table.ant-table-empty .ant-table-tbody>tr>td {
  border-bottom: 0 !important;
}

.ant-table.ant-table-empty .ant-table-thead {
  display: none;
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Notification */
.middle-notification {
  height: 64px;
  padding: 0 !important;
  border-radius: 4px !important;
  width: 700px !important;
  max-width: 80vw !important;
}

.ant-notification-notice {
  position: relative;
  min-height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 16px 20px -8px rgba(17, 12, 34, 0.10);
}

.ant-notification-notice.ant-notification-notice-error::before {
  position: absolute;
  content: '';
  height: 100%;
  left: 0;
  top: 0;
  width: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #F03D3D;
}

.ant-notification-notice.ant-notification-notice-success::before {
  position: absolute;
  content: '';
  height: 100%;
  left: 0;
  top: 0;
  width: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #0BAA60;
}

.ant-notification-notice-content {
  padding-left: 22px;
}

.ant-notification-notice-content .ant-notification-notice-with-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.ant-notification-notice-icon {
  margin-left: 0 !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message,
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin: 0 !important;
  padding-left: 40px;
  padding-right: 75px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0 !important;
  color: #676472 !important;
}

.ant-notification-notice-close {
  top: 12px;
}

.ant-notification-notice-close-x {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
}

.ant-table-column-sorter {
  display: none;
}

thead.ant-table-thead th.ant-table-cell {
  padding: 16px 12px !important;
  padding-left: 16px !important;
}

/* Custom Pagination */
.hr-pagination .ant-pagination-prev,
.hr-pagination .ant-pagination-next {
  display: none;
}

.hr-pagination .ant-pagination-item {
  border-radius: 4px;
}

.hr-pagination .ant-pagination-item>a {
  font-weight: 700;
}

.hr-pagination .ant-pagination-item.ant-pagination-item-active {
  border: 1px solid #7357FF !important;
}

.hr-pagination .ant-pagination-item.ant-pagination-item-active>a {
  color: #7357FF !important;
}


.ant-table-wrapper.white-header {
  background: white;
}

.ant-table-wrapper.white-header table {
  border: unset !important;
}

/**Custom Popover */

.hr-popover.ant-popover.hr-popover.ant-popover-placement-bottomRight {
  padding-top: 0px;
}

.hr-popover.ant-popover-inner-content {
  border-radius: 10px;
  padding: 0px !important;
}

/* Multi Select */
.ant-select-multiple .ant-select-selection-item {
  background: #8B75FF !important;
  border-radius: 4px !important;
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px !important;
  padding: 4px 8px;
  height: auto;
  gap: 8px;
}

.ant-select-multiple .ant-select-selection-item-remove {
  color: white !important;
}

/** Custom ant-ribbon */
.ant-ribbon {
  height: 25px !important;
  border-radius: 8px !important;
}

.ant-ribbon.tag-free.ant-ribbon-placement-start {
  color: #8E909E;
  background-color: transparent;
  top: 17px;
}

.tag-free::before {
  position: absolute;
  content: '';
  height: 25px;
  left: 0;
  top: 0;
  width: 8px;
  background-color: #A6A8B4;
  border-top-left-radius: 8px;
}

.ant-ribbon.tag-new.ant-ribbon-placement-start {
  color: #BA4343;
  background-color: transparent;
  top: 17px;
}

.tag-new::before {
  position: absolute;
  content: '';
  height: 25px;
  left: 0;
  top: 0;
  width: 8px;
  background-color: #F55858;
  border-top-left-radius: 8px;
}

.ant-ribbon.tag-private.ant-ribbon-placement-start {
  color: #A6A8B4;
  background-color: transparent;
  top: 17px;
}

.tag-private::before {
  position: absolute;
  content: '';
  height: 25px;
  left: 0;
  top: 0;
  width: 8px;
  background-color: #A6A8B4;
  border-top-left-radius: 8px;
}

.ant-ribbon.tag-popular.ant-ribbon-placement-start {
  color: #7357FF;
  background-color: transparent;
  top: 17px;
}

.tag-popular::before {
  position: absolute;
  content: '';
  height: 25px;
  left: 0;
  top: 0;
  width: 8px;
  background-color: #7357FF;
  border-top-left-radius: 8px;
}

.ant-ribbon.tag-paid.ant-ribbon-placement-start {
  color: #DFAD2E;
  background-color: transparent;
  top: 17px;
}

.tag-paid::before {
  position: absolute;
  content: '';
  height: 25px;
  left: 0;
  top: 0;
  width: 8px;
  background-color: #FFCB46;
  border-top-left-radius: 8px;
}

.tag-new.tag-paid::before {
  background-color: #F55858;
}

.ant-ribbon.tag-new.tag-paid.ant-ribbon-placement-start {
  color: #BA4343;
}

.ant-ribbon.tag-popular.tag-paid.ant-ribbon-placement-start {
  color: #7357FF;
}

.tag-popular.tag-paid::before {
  background-color: #7357FF;
}

/* icon */
.icon-new span {
  left: 30px;
}

.icon-popular span {
  left: 20px;
}

.icon-free span {
  left: 20px;
}

.icon-paid {
  color: #2E3046;
}

.icon-paid span {
  left: 20px;
}

.ant-typography {
  margin-bottom: 0 !important;
}


/* Custom Tabs */
.full-width-tabs .ant-tabs-nav-list {
  width: 100%;
}

.full-width-tabs .ant-tabs-tab {
  width: 33.3333333%;
  padding: 16px 32px !important;
  border: 0 !important;
  border-radius: 0 !important;
  
}

.full-width-tabs .ant-tabs-tab-active {
  border-radius: 12px 12px 0 0 !important;
  background-color: white !important;
  box-shadow: 0px 6px 16px -6px rgba(17, 12, 34, 0.10);
}

.full-width-tabs .ant-tabs-tab-btn {
  width: 100%;
}

.full-width-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #46485C !important;
}

/* Custom Text high light */
.text-highlight {
  background-color: #FAD640;
}

/* Custom Tooltip */
.gray .ant-tooltip-arrow-content:before {
  background: #D8E0FC !important;
}

.gray .ant-tooltip-inner {
  color: #46485C !important;
  background: #D8E0FC !important;
}

/* Custom Add link on Editor */
.rdw-link-modal {
  min-height: 205px;
  height: auto !important;
}

/* Custom Switch control */
.ant-switch {
  height: 14px !important;
  background-color: #D9D8DC;
}

.ant-switch-checked {
  background: rgba(88, 86, 214, 0.5) !important;
  height: 14px !important;
}

.ant-switch-handle {
  height: 20px !important;
  width: 20px !important;
  top: -4px !important;
}

.ant-switch .ant-switch-handle:before {
  background-color: white !important;
  left: 0 !important;
}

.ant-switch-checked .ant-switch-handle:before {
  background-color: #5856D6 !important;
}

/* Custom Radio control */
.ant-radio-inner {
  border-color: #46485C !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #7357FF !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #7357FF;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #7357FF;
}

/* Custom Remove Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
.ant-input-number-handler-wrap {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.hr-input-number .ant-input-suffix {
  margin-bottom: 4px;
}

/* Custom Checkbox group */
.ant-checkbox-group .ant-checkbox-inner {
  border: 2px solid #46485C;
  border-radius: 4px;
  height: 20px;
  width: 20px;
}

.ant-checkbox-group .ant-checkbox+span {
  padding-left: 10px;
}

.ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner::after {
  left: 27.5%;
}

/* Custom Badge */
.badge-popular .ant-badge-count {
  background-color: #7357FF;
  color: white;
}

.badge-free .ant-badge-count {
  background-color: #F55858;
  color: white;
}

/* Custom Step sticky */
.header-sticky {
  background: white;
  position: fixed;
  width: 100%;
  z-index: 10;
  max-width: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  animation: moveDown .3s ease-in-out;
}
@media screen and (min-width: 1024px) {
  .question-test-sticky {
    background: white;
    position: fixed;
    width: 100%;
    min-height: 120px;
    max-height: 40vh;
    overflow-y: auto;
    padding: 16px;
    z-index: 10;
    top: 58px;
    left: 0;
    z-index: 999;
    box-shadow: 0px 4px 16px rgba(79, 117, 140, 0.24);
    animation: moveDown .5s ease-in-out;
  }
}


@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }

  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }

  100% {
    transform: rotateY(0rem);
  }
}

.bg-purple-linear {
  background: linear-gradient(90deg, #7357FF 0%, #6E4CF8 100.31%);
}

.invite-email-modal .ant-modal-content {
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(98, 112, 140, 0.24);
}

.policy-modal .ant-modal-close {
  right: 32px;
  top: 16px;
}

.policy-modal .ant-modal-close .ant-modal-close-x {
  width: auto;
}

.user-dropdown .ant-dropdown-menu-item {
  padding: 0px !important;
}

.user-dropdown .ant-dropdown-menu {
  border-radius: 12px;
  padding: 12px 0;
}

/* ant-popover-content */
.ant-popover-content {
  box-shadow: 0px 4px 16px 0px rgba(98, 112, 140, 0.24);
  border-radius: 12px;
  box-sizing: border-box;
  overflow: hidden;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
  padding: 16px 0 !important;
}

.ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
  margin-left: 0;
}

/* Custom Select control */
.ant-select-dropdown {
  z-index: 99 !important;
}

/* override draft control */
.questions-editor.editor-editor.has-value .public-DraftEditorPlaceholder-root {
  display: none;
}

.editor-editor.editor-essay-editor {
  min-height: 250px;
}

.editor-editor.editor-essay-editor .DraftEditor-editorContainer .public-DraftEditor-content {
  min-height: 250px;
}
